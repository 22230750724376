import React from "react";
import { useSelector } from "react-redux";
import OrderTable from "./OrderTable";
import SelectedOrderDetails from "./SelectedOrderDetails";
import { RootState } from "store/store";

export default function OrderLayout() {
  const selectedOrderId = useSelector(
    (state: RootState) => state.ordersList.selectedOrderId
  );

  return (
    <div className="h-[90vh] flex w-full">
      <div
        className={`transition-all duration-300 ease-in-out ${
          selectedOrderId ? "w-3/4 border-r" : "w-full"
        }`}
      >
        <OrderTable />
      </div>

      {selectedOrderId && (
        <div className="w-1/4">
          <SelectedOrderDetails />
        </div>
      )}
    </div>
  );
}
