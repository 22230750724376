import { Button } from "../../../@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../../../@/components/ui/dialog";
import React from "react";

interface DeleteDataConfirmationDialogProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
  }
  
  const DeleteDataConfirmationDialog: React.FC<DeleteDataConfirmationDialogProps> = ({
    open,
    onCancel,
    onConfirm,
  }) => {
    return(
        <Dialog open={open} onOpenChange={onCancel}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Delete Data</DialogTitle>
          <DialogDescription>Are you sure you want to delete this data? This action cannot be undone.</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    );
}
export default DeleteDataConfirmationDialog;