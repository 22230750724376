import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../@/components/ui/card";
import { ScrollArea } from "../../../../@/components/ui/scroll-area";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders } from "store/SelectedUserOrdersSlice";
import { AppDispatch, RootState } from "store/store";
import ActivityLoadingComponent from "../activityLogSection/activityLogComponents/ActivityLoadingComponent";
import ErrorComponent from "pageComponents/chatSection/errorComponent/ErrorComponent";
import NoActivityComponent from "../activityLogSection/activityLogComponents/NoActivityComponent";
import { Button } from "../../../../@/components/ui/button";
import { Copy,  EditIcon } from "lucide-react";
// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuItem,
//   DropdownMenuSeparator,
//   DropdownMenuTrigger,
// } from "../../../../@/components/ui/dropdown-menu";
import { Separator } from "@radix-ui/react-select";
import { format } from "date-fns";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  // SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import { fetchLeadList } from "store/leadTemplate";
import { updateOrderStatus } from "store/OrdersSlice";
import { useToast } from "@/hooks/use-toast";

const formatTimestamp = (timestamp: number) => {
  return format(new Date(timestamp), "dd MMM yyyy, HH:mm");
};

// function formatTimestamp(timestamp: number) {
//   const date = new Date(timestamp);
//   return date.toLocaleString();
// }

export default function OrderDetailsCard() {
  const dispatch: AppDispatch = useDispatch();
  const { toast } = useToast();
  const userId = useSelector((state: RootState) => state.selectedUser.userId);
  const { orders, loading, error } = useSelector(
    (state: RootState) => state.orders
  );
  const business = useSelector((state: RootState) => state.auth.business);
  const [editingOrderId, setEditingOrderId] = useState<string | null>(null);
  const [leadTemplates, setLeadTemplates] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  type StatusKey =
    | "created"
    | "confirm"
    | "confirmed"
    | "cancelled"
    | "dispatched";

  const statusMapping: Record<StatusKey, string> = {
    created: "Partial Order",
    confirm: "New Order",
    confirmed: "Confirmed",
    cancelled: "Cancelled",
    dispatched: "Dispatched",
  };

  console.log("orderssssss", orders);

  useEffect(() => {
    if (userId) {
      dispatch(fetchOrders(userId));
    }
  }, [dispatch, userId]);

  const handleEdit = (orderId: string, currentStatus: string) => {
    setEditingOrderId(orderId);
    setSelectedStatus(currentStatus);
  };

  useEffect(() => {
    if (business) {
      dispatch(fetchLeadList({ business })).then((response: any) => {
        setLeadTemplates(response.payload);
      });
    }
  }, [dispatch, business]);

  const handleStatusChange = (newStatus: string) => {
    setSelectedStatus(newStatus);
  };

  const handleSave = (orderId: string) => {
    if (selectedStatus && orderId) {
      const statusKey = (Object.keys(statusMapping) as StatusKey[]).find(
        (key) => statusMapping[key] === selectedStatus
      );

      if (statusKey) {
        dispatch(updateOrderStatus({ orderId, status: statusKey }))
          .then(() => {
            if (userId) {
              dispatch(fetchOrders(userId));
            }
            toast({
              title: "Order Updated",
              description: `The order status has been updated to ${statusMapping[statusKey]}.`,
            });
          })
          .catch((error) => {
            console.error("Failed to update order status:", error);
            toast({
              title: "Update Failed",
              description: "There was an issue updating the order status.",
              variant: "destructive",
            });
          });
      }
    }
    setEditingOrderId(null);
  };

  const formatPaymentMethod = (paymentMethod: string) => {
    return paymentMethod.replace("_", " ");
  };

  return (
    <ScrollArea className="h-[80vh]">
      <Card className="ml-2 mr-2 sm:col-span-2 border-0 ">
        {loading ? (
          <ActivityLoadingComponent />
        ) : error ? (
          <ErrorComponent />
        ) : orders.length > 0 ? (
          orders.map((order) => (
            <Card
              key={order._id}
              className="overflow-hidden mb-5"
              x-chunk="dashboard-05-chunk-4"
            >
              <CardHeader className="flex flex-column items-start bg-muted/50">
                <div className="grid gap-0.5">
                  <CardTitle className="group flex items-center gap-2 text-sm">
                    Order {order._id}
                    <Button
                      size="icon"
                      variant="outline"
                      className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100"
                    >
                      <Copy className="h-3 w-3" />
                      <span className="sr-only">Copy Order ID</span>
                    </Button>
                  </CardTitle>
                  <CardDescription>
                    {order.status.length > 0 && (
                      <>
                        <span>
                          Status:{" "}
                          {statusMapping[
                            order.status[order.status.length - 1]
                              .activity as StatusKey
                          ] || "Unknown Status"}
                        </span>
                        <span className="ml-2 text-xs text-gray-500">
                          Date:{" "}
                          {formatTimestamp(
                            order.status[order.status.length - 1].timestamp
                          )}
                        </span>
                      </>
                    )}
                  </CardDescription>
                </div>
                {editingOrderId === order._id ? (
                  <button
                    className="text-sm font-medium ml-auto h-6 w-auto text-gray-600 flex items-center gap-1 hover:text-gray-500 transition-colors"
                    onClick={() => handleSave(order._id)}
                  >
                    <EditIcon className="h-4 w-4" />
                    <span>Save</span>
                  </button>
                ) : (
                  <button
                    className="text-sm font-medium ml-auto h-6 w-auto text-gray-600 flex items-center gap-1 hover:text-gray-500 transition-colors"
                    onClick={() =>
                      handleEdit(
                        order._id,
                        order.status[order.status.length - 1].activity
                      )
                    }
                  >
                    <EditIcon className="h-4 w-4" />
                    <span>Edit Order Status</span>
                  </button>
                )}
                {editingOrderId === order._id && (
                  <Select onValueChange={handleStatusChange}>
                    <SelectTrigger className="w-full">
                      <SelectValue
                        placeholder={
                          statusMapping[
                            order.status[order.status.length - 1]
                              .activity as StatusKey
                          ]
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {leadTemplates.map((template) => (
                          <React.Fragment key={template.lead_template_id}>
                            {template.lead_template_status_list.map(
                              (status: string) => {
                                const displayName =
                                  statusMapping[status as StatusKey] || status;
                                return (
                                  <SelectItem
                                    key={status}
                                    value={status}
                                    className="capitalize"
                                  >
                                    {displayName}
                                  </SelectItem>
                                );
                              }
                            )}
                          </React.Fragment>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                )}
              </CardHeader>
              <CardContent className="p-6 text-sm">
                <div className="grid gap-3">
                  <div className="font-semibold">Order Details</div>
                  {order.product_details?.map((product, index) => (
                    <div key={index}>
                      <ul className="grid gap-3">
                        <li className="flex items-center justify-between">
                          <span className="text-muted-foreground">
                            {product.name} x <span>{product.quantity}</span>
                          </span>
                          <span>
                            Rs.{(product.sale_price / 100).toFixed(2)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  ))}

                  <Separator className="my-2 bg-slate-300" />
                  <ul className="grid gap-3">
                    <li className="flex items-center justify-between">
                      <span className="text-muted-foreground">Subtotal</span>
                      <span>Rs.{(order.sale_price / 100).toFixed(2)}</span>
                    </li>
                    {order.billing_details && (
                      <li className="flex items-center justify-between">
                        <span className="text-muted-foreground">Shipping</span>
                        <span>
                          Rs.
                          {(
                            order.billing_details.delivery_charge / 100
                          ).toFixed(2)}
                        </span>
                      </li>
                    )}

                    <li className="flex items-center justify-between font-semibold">
                      <span className="text-muted-foreground">Total</span>
                      <span>
                        Rs.
                        {(
                          order.sale_price / 100 +
                          (order.billing_details?.delivery_charge || 0) / 100
                        ).toFixed(2)}
                      </span>
                    </li>
                  </ul>
                </div>
                <Separator className="my-4" />
                <div className="grid grid-cols-2 gap-4">
                  {order.billing_details && (
                    <>
                      <div className="grid gap-3">
                        <div className="font-semibold">
                          Shipping Information
                        </div>

                        <address className="grid gap-0.5 not-italic text-muted-foreground">
                          {order.billing_details.address}
                        </address>
                      </div>
                      <div className="grid auto-rows-max gap-3">
                        <div className="font-semibold">Billing Information</div>
                        <div className="text-muted-foreground">
                          Same as shipping address
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <Separator className="my-4" />
                <div className="grid gap-3">
                  {order.billing_details && (
                    <>
                      <div className="font-semibold">Customer Information</div>
                      <dl className="grid gap-3">
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Customer</dt>
                          <dd>{order.billing_details.name}</dd>
                        </div>
                        <div className="flex items-center justify-between">
                          <dt className="text-muted-foreground">Phone</dt>
                          <dd>
                            <a href="tel:">{order.billing_details.phone}</a>
                          </dd>
                        </div>
                      </dl>
                    </>
                  )}
                </div>
                <Separator className="my-4" />
                {order.order_details && (
                  <div className="grid gap-3">
                    <div className="font-semibold">Payment Information:</div>
                    <dl className="grid gap-3">
                      <div className="flex items-center justify-between">
                        <dt className="text-muted-foreground">
                          Payment Method
                        </dt>
                        <dd>{formatPaymentMethod(order.order_details.paymentmethod)}</dd>
                      </div>
                    </dl>
                  </div>
                )}
              </CardContent>
            </Card>
          ))
        ) : (
          <NoActivityComponent />
        )}
      </Card>
    </ScrollArea>
  );
}
