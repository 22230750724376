import { Input } from "../../../../@/components/ui/input";
import { Search, AlignRight, User } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../@/components/ui/button";
import { Checkbox } from "../../../../@/components/ui/checkbox";
import { setSearchTerm } from "../../../../store/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchLeadList, setSelectedTemplate } from "store/leadTemplate";
import { Label } from "../../../../@/components/ui/label";
import { useMedia } from "react-use";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../@/components/ui/dialog";
import { fetchAdminList, setFitlterSelectedAdminId } from "store/adminSlice";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { ScrollArea } from "../../../../@/components/ui/scroll-area";

export default function ChatListHeader() {
  const dispatch: AppDispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const [leadTemplates, setLeadTemplates] = useState<any[]>([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedTemplate, setLocalSelectedTemplate] = useState<string | null>(
    null
  );
  const [selectedAdminId, setSelectedAdminId] = useState<string | null>(null);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { searchTerm } = useSelector((state: RootState) => state.chat);
  const business = useSelector((state: RootState) => state.auth.business);
  const admins = useSelector((state: RootState) => state.admin.admins);
  const isMobile = useMedia("(max-width: 640px)");
  const isECommerce = useSelector((state: RootState) => state.chat.isECommerce);

  useEffect(() => {
    if (business) {
      dispatch(fetchLeadList({ business })).then((response: any) => {
        setLeadTemplates(response.payload);
      });
    }
  }, [dispatch, business]);

  useEffect(() => {
    if (business) {
      dispatch(fetchAdminList({ business }));
    }
  }, [business, dispatch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchTerm(event.target.value));
  };
  const handleSearchClick = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };

  // const togglePopover = () => {
  //   setPopoverOpen((prevOpen) => !prevOpen);
  // };

  const togglePopoverOpen = () => setPopoverOpen((prevOpen) => !prevOpen);

  const toggleDropdownOpen = () => {
    setDropdownOpen((prevOpen) => !prevOpen);
  };

  // const handleStatusChange = (status: string) => {
  //   setSelectedStatuses((prevSelected) =>
  //     prevSelected.includes(status)
  //       ? prevSelected.filter((item) => item !== status)
  //       : [...prevSelected, status]
  //   );
  // };

  const handleTemplateSelection = (
    templateName: string,
    templateId: string
  ) => {
    if (selectedTemplate === templateName) {
      setLocalSelectedTemplate(null);
      setSelectedStatuses([]);

      dispatch(
        setSelectedTemplate({
          templateId: "all",
          statuses: [],
        })
      );
    } else {
      setLocalSelectedTemplate(templateName);
      setSelectedStatuses([]);

      const selectedTemplateObj = leadTemplates.find(
        (template) => template.lead_template_name === templateName
      );

      if (selectedTemplateObj) {
        dispatch(
          setSelectedTemplate({
            templateId: selectedTemplateObj.lead_template_id,
            statuses: [],
          })
        );
      }
    }
  };

  const handleStatusChange = (status: string) => {
    const newSelectedStatuses = selectedStatuses.includes(status)
      ? []
      : [status];

    setSelectedStatuses(newSelectedStatuses);

    if (isECommerce) {
      const statusMap: Record<string, string> = {
        "Partial Order": "created",
        "New Order": "confirm",
        Confirmed: "confirmed",
        Cancelled: "cancelled",
        Dispatched: "dispatched",
      };

      const mappedStatuses = newSelectedStatuses.map((s) => statusMap[s] || s);

      dispatch(
        setSelectedTemplate({
          templateId: "all",
          statuses: mappedStatuses,
        })
      );
    } else if (selectedTemplate) {
      const selectedTemplateObj = leadTemplates.find(
        (template) => template.lead_template_name === selectedTemplate
      );

      if (selectedTemplateObj) {
        dispatch(
          setSelectedTemplate({
            templateId: selectedTemplateObj.lead_template_id,
            statuses: newSelectedStatuses,
          })
        );
      }
    }
  };

  const handleOwnerSelection = (leadOwnerId: string) => {
    if (selectedAdminId === leadOwnerId) {
      setSelectedAdminId(null);
      dispatch(setFitlterSelectedAdminId("all"));
    } else {
      setSelectedAdminId(leadOwnerId);
      dispatch(setFitlterSelectedAdminId(leadOwnerId));
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-3 items-center justify-between">
        {!showSearch && (
          <Button
            variant="ghost"
            className="p-2 hover:bg-gray-100 transition-all"
            onClick={handleSearchClick}
          >
            <Search className="h-5 w-5 mr-2" />
            <span>Search</span>
          </Button>
        )}
        {showSearch && (
          <form className="flex-1 sm:flex-initial mt-2">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search by a mobile number..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="pl-8 sm:w-[14rem] md:w-[16rem] lg:w-[18rem] border border-gray-300 rounded-lg focus:border-gray-400 focus:ring-1 focus:ring-gray-400"
              />
            </div>
          </form>
        )}
        {isMobile ? (
          <Dialog open={popoverOpen} onOpenChange={setPopoverOpen}>
            <DialogTrigger asChild>
              <Button
                variant="ghost"
                className="p-2 hover:bg-gray-100 transition-all"
                onClick={togglePopoverOpen}
              >
                <AlignRight className="h-5 w-5 text-gray-700" />
              </Button>
            </DialogTrigger>

            <DialogContent className="w-[60%] h-[60%]  bg-white p-4">
              <ScrollArea className=" ">
                <DialogHeader>
                  <DialogTitle className="text-sm">Filter By</DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                  <div className="px-2">
                    <h3 className="text-sm font-medium pb-1">Contact Owner</h3>
                    {admins.length === 0 ? (
                      <div className="text-xs text-gray-500">
                        No contact owners available.
                      </div>
                    ) : (
                      admins.map((admin) => (
                        <div key={admin._id} className="flex items-center">
                          <Checkbox
                            checked={selectedAdminId === admin._id}
                            onCheckedChange={() =>
                              handleOwnerSelection(admin._id)
                            }
                            id={admin.name}
                            className="mr-2"
                          />
                          <Label
                            htmlFor={admin.name}
                            className="text-sm font-normal text-gray-700 capitalize"
                          >
                            {admin.name}
                          </Label>
                        </div>
                      ))
                    )}
                  </div>

                  <div className="px-2">
                    <h3 className="text-sm font-medium">Lead Status</h3>
                    {leadTemplates.length === 0 ? (
                      <div className="text-xs text-gray-500">
                        No lead templates available.
                      </div>
                    ) : (
                      leadTemplates.map((template) => (
                        <div key={template.lead_template_id}>
                          <Checkbox
                            checked={
                              selectedTemplate === template.lead_template_name
                            }
                            onCheckedChange={() =>
                              handleTemplateSelection(
                                template.lead_template_name,
                                template.lead_template_id
                              )
                            }
                            id={template.lead_template_name}
                            className="mr-2"
                          />
                          <Label
                            htmlFor={template.lead_template_name}
                            className="text-sm font-medium text-gray-700 capitalize"
                          >
                            {template.lead_template_name}
                          </Label>
                          <div className="ml-6">
                            {template.lead_template_status_list.map(
                              (status: string) => (
                                <div key={status} className="flex items-center">
                                  <Checkbox
                                    checked={selectedStatuses.includes(status)}
                                    onCheckedChange={() =>
                                      handleStatusChange(status)
                                    }
                                    id={status}
                                    className="mr-2"
                                    disabled={
                                      selectedTemplate !==
                                      template.lead_template_name
                                    }
                                  />
                                  <Label
                                    htmlFor={status}
                                    className="text-sm capitalize font-normal"
                                  >
                                    {status}
                                  </Label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </ScrollArea>
            </DialogContent>
          </Dialog>
        ) : (
          <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                className="p-2 hover:bg-gray-100 transition-all"
                onClick={toggleDropdownOpen}
              >
                <AlignRight className="h-5 w-5 text-gray-700" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[18vw]">
              <DropdownMenuLabel>Filter By</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <div className="px-2">
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <User className="mr-2 h-4 w-4" />
                    <span>Contact Owner</span>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuSubContent className="pl-6">
                    {admins.length === 0 ? (
                      <div className="text-gray-500">
                        No contact owners available.
                      </div>
                    ) : (
                      admins.map((admin) => (
                        <DropdownMenuItem key={admin._id}>
                          <Checkbox
                            checked={selectedAdminId === admin._id}
                            onCheckedChange={() =>
                              handleOwnerSelection(admin._id)
                            }
                            id={admin.name}
                            className="mr-2"
                            onClick={(e) => e.stopPropagation()}
                          />
                          <Label
                            htmlFor={admin.name}
                            className="text-sm font-medium text-gray-700"
                          >
                            {admin.name}
                          </Label>
                        </DropdownMenuItem>
                      ))
                    )}
                  </DropdownMenuSubContent>
                </DropdownMenuSub>
              </div>

              <DropdownMenuSeparator />
              <div className="px-2">
                {leadTemplates.length === 0 ? (
                  <div className="text-gray-500">
                    No lead templates available.
                  </div>
                ) : (
                  leadTemplates.map((template) => (
                    <DropdownMenuSub key={template.lead_template_id}>
                      <DropdownMenuSubTrigger>
                        <Checkbox
                          checked={
                            selectedTemplate === template.lead_template_name
                          }
                          onCheckedChange={() =>
                            handleTemplateSelection(
                              template.lead_template_name,
                              template.lead_template_id
                            )
                          }
                          id={template.lead_template_name}
                          className="mr-2"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Label
                          htmlFor={template.lead_template_name}
                          className="text-sm font-medium text-gray-700"
                        >
                          {template.lead_template_name}
                        </Label>
                      </DropdownMenuSubTrigger>
                      <DropdownMenuSubContent className="pl-6">
                        {isECommerce
                          ? [
                              "Partial Order",
                              "New Order",
                              "Confirmed",
                              "Cancelled",
                              "Dispatched",
                            ].map((status) => (
                              <DropdownMenuItem key={status}>
                                <Checkbox
                                  checked={selectedStatuses.includes(status)}
                                  onCheckedChange={() =>
                                    handleStatusChange(status)
                                  }
                                  id={status}
                                  className="mr-2"
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <Label htmlFor={status} className="text-sm">
                                  {status}
                                </Label>
                              </DropdownMenuItem>
                            ))
                          : template.lead_template_status_list.map(
                              (status: string) => (
                                <DropdownMenuItem key={status}>
                                  <Checkbox
                                    checked={selectedStatuses.includes(status)}
                                    onCheckedChange={() =>
                                      handleStatusChange(status)
                                    }
                                    id={status}
                                    className="mr-2"
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <Label htmlFor={status} className="text-sm">
                                    {status}
                                  </Label>
                                </DropdownMenuItem>
                              )
                            )}
                      </DropdownMenuSubContent>
                    </DropdownMenuSub>
                  ))
                )}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
}
