import { useDispatch, useSelector } from "react-redux";
import { Label } from "../../../../@/components/ui/label";
import { Switch } from "../../../../@/components/ui/switch";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "store/store";
import { fetchStopReplyStatus,  updateStopReplyStatus } from "store/AIReplySlice";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../../../../@/components/ui/dialog";
import { Button } from "../../../../@/components/ui/button";

export default function AIReplyHandleButton() {
    const dispatch: AppDispatch = useDispatch();
    const chatroomId = useSelector((state: RootState) => state.selectedUser.chatroomId);
    const { message: stopReplying, status } = useSelector((state: RootState) => state.aiReply);
    
    const [isChecked, setIsChecked] = useState(!stopReplying);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pendingChange, setPendingChange] = useState(false);
  
    useEffect(() => {
      if (chatroomId) {
        dispatch(fetchStopReplyStatus({ chatroomId }));
      }
    }, [chatroomId, dispatch]);
  
    useEffect(() => {
      setIsChecked(!stopReplying);
    }, [stopReplying]);
  
    const handleToggle = () => {
      setPendingChange(!isChecked);
      setDialogOpen(true);
    };
  
    const handleConfirmChange = () => {
      if (chatroomId && pendingChange !== null) {
        setIsChecked(pendingChange);
        dispatch(updateStopReplyStatus({ chatroomId, stopReplying: !pendingChange }));
      }
      setDialogOpen(false);
    };
  
  
    return (
      <>
      <div className="flex items-center space-x-2 ml-auto">
        <Label htmlFor="ai-replying">AI Replying</Label>
        <Switch
          id="ai-replying"
          checked={isChecked}
          onCheckedChange={handleToggle}
          disabled={status === 'loading' || !chatroomId}
        />
      </div>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Confirm AI Reply Toggle</DialogTitle>
            <DialogDescription>
              Are you sure you want to {pendingChange ? "enable" : "disable"} AI replying for this chatroom?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex flex-row justify-end space-x-2">
            <Button variant="outline" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleConfirmChange}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      </>
    );
}
