import React from "react";
import ChatSection from "pageComponents/chatSection/ChatSection";

const ChatPage: React.FC = () => {
    return (
        <div >
          <ChatSection/>
        </div>
      );
}
export default ChatPage;