import { File, ListFilter} from "lucide-react";

import { Badge } from "../../../@/components/ui/badge";
import { Button } from "../../../@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../@/components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../@/components/ui/tabs";
//import OrderHeaderSection from "./OrderHeaderSection";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../@/components/ui/pagination";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchOrderList, setSelectedOrderId } from "store/OrdersSlice";

const getBadgeColor = (activity: string) => {
  switch (activity) {
    case "created":
      return "ring-1 ring-blue-500 text-blue-500 ";
    case "confirm":
      return "ring-1 ring-green-500 text-green-500";
    case "cancelled":
      return "ring-1 ring-red-500 text-red-500";
    default:
      return "ring-1 ring-gray-300 text-gray-300";
  }
};

export default function OrderTable() {
  const dispatch = useDispatch<AppDispatch>();
  const ordersList = useSelector((state: RootState) => state.ordersList.orders);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const orderStatus = useSelector(
    (state: RootState) => state.ordersList.status
  );
  console.log("businessss ordersss...", ordersList);
  const [orderTime, setOrderTime] = useState('all');

  const initialStatus = "all";

  useEffect(() => {
    dispatch(fetchOrderList({ time: orderTime, status: initialStatus }));
  }, [dispatch, orderTime, initialStatus]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages] = useState(1);

  const handleTimeChange = (newTime: string) => {
    setOrderTime(newTime);
    setCurrentPage(1); 
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  

  return (
    <div className="flex w-full flex-col ">
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-2 sm:pr-2">
        <main className="flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
          <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
            {/* <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
              <OrderHeaderSection />
            </div> */}
            <Tabs defaultValue="all">
              <div className="flex items-center">
                <TabsList>
                <TabsTrigger 
                  value="all"
                  onClick={() => handleTimeChange("all")}
                  >All</TabsTrigger>
                  <TabsTrigger 
                  value="week"
                  onClick={() => handleTimeChange("week")}
                  >Week</TabsTrigger>
                  <TabsTrigger 
                  value="month"
                  onClick={() => handleTimeChange("month")}
                  >Month</TabsTrigger>
                  <TabsTrigger 
                  value="year"
                  onClick={() => handleTimeChange("year")}
                  >Year</TabsTrigger>
                </TabsList>
                <div className="ml-auto flex items-center gap-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-7 gap-1 text-sm"
                      >
                        <ListFilter className="h-3.5 w-3.5" />
                        <span className="sr-only sm:not-sr-only">Filter</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuCheckboxItem checked>
                        Fulfilled
                      </DropdownMenuCheckboxItem>
                      <DropdownMenuCheckboxItem>
                        Declined
                      </DropdownMenuCheckboxItem>
                      <DropdownMenuCheckboxItem>
                        Refunded
                      </DropdownMenuCheckboxItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <Button
                    size="sm"
                    variant="outline"
                    className="h-7 gap-1 text-sm"
                  >
                    <File className="h-3.5 w-3.5" />
                    <span className="sr-only sm:not-sr-only">Export</span>
                  </Button>
                </div>
              </div>
              <TabsContent value={orderTime}>
                <Card x-chunk="dashboard-05-chunk-3">
                  <CardHeader className="px-7">
                    <CardTitle className="text-sm">Orders</CardTitle>
                    <CardDescription>
                      Recent orders from your store.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead></TableHead>
                          <TableHead>Customer</TableHead>
                         
                          <TableHead className="hidden sm:table-cell">
                            Status
                          </TableHead>
                          <TableHead className="hidden md:table-cell">
                            Date
                          </TableHead>
                          <TableHead className="text-right">Amount (Rs.)</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {ordersList.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={9} className="text-center py-4">
                              No data available
                            </TableCell>
                          </TableRow>
                        ) : (
                          ordersList.map((order, index) => (
                            <TableRow
                              key={order._id}
                              className="cursor-pointer"
                              onClick={() => dispatch(setSelectedOrderId(order._id))}
                            >
                              <TableCell>
                                {(currentPage - 1) * itemsPerPage + index + 1}
                              </TableCell>
                              <TableCell>
                                <div className="font-medium capitalize">
                                {order.billing_details?.name || 'Unknown User'}
                                </div>
                                <div className="hidden text-sm text-muted-foreground md:inline">
                                {order.billing_details?.phone || 'Phone Number Not Specified'}
                                </div>
                              </TableCell>
                              
                              <TableCell className="hidden sm:table-cell">
                                <Badge
                                  className={`text-xs ${getBadgeColor(
                                    order.status[order.status.length - 1]
                                      ?.activity
                                  )}`}
                                  variant="secondary"
                                >
                                  {order.status.length > 0
                                    ? order.status[order.status.length - 1]
                                        .activity
                                    : "No Status"}
                                </Badge>
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                {order.status.length > 0
                                  ? new Date(
                                      order.status[
                                        order.status.length - 1
                                      ].timestamp
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                  : "No Date"}
                              </TableCell>

                              
                              <TableCell className="text-right">
                              {(order.sale_price / 100).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
                <div className="flex justify-center mt-4">
                  <Pagination>
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          href="#"
                          onClick={(e) => {
                            if (currentPage === 1) {
                              e.preventDefault();
                            } else {
                              handlePageChange(currentPage - 1);
                            }
                          }}
                          className={`${
                            currentPage === 1
                              ? "pointer-events-none text-gray-400"
                              : ""
                          }`}
                        />
                      </PaginationItem>

                      {Array.from({ length: totalPages }, (_, index) => (
                        <PaginationItem key={index}>
                          <PaginationLink
                            href="#"
                            onClick={() => handlePageChange(index + 1)}
                            className={`${
                              currentPage === index + 1 ? "text-bold" : ""
                            }`}
                          >
                            {index + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      <PaginationItem>
                        <PaginationEllipsis />
                      </PaginationItem>

                      <PaginationItem>
                        <PaginationNext
                          href="#"
                          onClick={(e) => {
                            if (currentPage === totalPages) {
                              e.preventDefault();
                            } else {
                              handlePageChange(currentPage + 1);
                            }
                          }}
                          className={`${
                            currentPage === totalPages
                              ? "pointer-events-none text-gray-400"
                              : ""
                          }`}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </main>
      </div>
    </div>
  );
}
