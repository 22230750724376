import React from "react";
import { BiSolidError } from "react-icons/bi";

export default function ErrorComponent(){
    return (
        <div className="flex flex-col items-center justify-center h-full text-center bg-white">
        <div className="mb-4 flex flex-col items-center">
          <BiSolidError className="text-gray-300" size={50} />
          <h3 className="text-sm font-semibold text-gray-500 mt-4">
            Error loading data.
          </h3>
        </div>
      </div>
    );
}