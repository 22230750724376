import { Button } from "../../../@/components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../@/components/ui/accordion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDataConfirmationDialog from "./DeleteDataConfirmationDialog";

export default function NavigationSettingComponent() {
  const navigate = useNavigate();
  const [deleteDataDialogOpen, setDeleteDataDialogOpen] = useState(false);

  const sections = [
    {
      id: "collection",
      title: "Information We Collect",
      content: `We collect minimal information necessary for app functionality:
      • Device information
      • App preferences
      • Usage statistics`,
    },
    {
      id: "usage",
      title: "How We Use Your Data",
      content: `Your information is used only to:
      • Provide app services
      • Improve user experience
      • Fix technical issues`,
    },
    {
      id: "protection",
      title: "How We Protect Your Data",
      content: `We use industry-standard security measures to protect your information. Data is encrypted and stored securely.`,
    },
    {
      id: "rights",
      title: "Your Rights",
      content: `You have the right to:
      • Access your data
      • Request deletion
      • Opt-out of collection`,
    },
  ];

  // const handleBack = () => {
  //   navigate(-1);
  // };

  const handleLogout = () => {
    setDeleteDataDialogOpen(false);
    navigate(-1);
  };

  const handleCancel = () => {
    setDeleteDataDialogOpen(false);
  };

  const handleLogoutClick = () => {
    setDeleteDataDialogOpen(true);
  };
  
  const handleConfirmLogout = () => {
    handleLogout();
    setDeleteDataDialogOpen(false);
  };

  // const toggleSection = (sectionId: string) => {
  //   if (expandedSection === sectionId) {
  //     setExpandedSection("");
  //   } else {
  //     setExpandedSection(sectionId);
  //   }
  // };
  return (
    <div className=" mx-auto p-4 space-y-4">
      <div className="flex items-center mb-6">
        {/* <button
          onClick={handleBack}
          className="flex items-center text-gray-600 hover:text-gray-800"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button> */}
      </div>
      <div className="mx-auto p-4 space-y-4 max-w-2xl">
        <div className="text-center mb-8">
          <div className="text-sm font-bold text-gray-900">Privacy Policy</div>
          <div className="text-sm text-gray-600 mt-1">
            Last updated: October 22, 2024
          </div>
        </div>

        <div className="space-y-4">
          <Accordion type="single" collapsible className="w-full">
            {sections.map((section) => (
              <AccordionItem key={section.id} value={`item-${section.id}`}>
                <AccordionTrigger className="text-sm">{section.title}</AccordionTrigger>
                <AccordionContent className="text-sm">{section.content}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>

        <div className="mt-8 space-y-4">
          <Button
            onClick={() => window.open("mailto:support@yourapp.com")}
            className="w-full py-2 px-4  text-white rounded-lg  transition-colors"
          >
            Contact Us
          </Button>

          <Button
            onClick={handleLogoutClick}
            className="w-full py-2 px-4 bg-red-600 rounded-lg hover:bg-red-600 transition-colors"
          >
            Request to Delete Data
          </Button>
        </div>
        <DeleteDataConfirmationDialog
            open={deleteDataDialogOpen}
            onCancel={handleCancel}
            onConfirm={handleConfirmLogout}
          />
      </div>
    </div>
  );
}
