import { ScrollArea } from "../../../../../@/components/ui/scroll-area";
import { Avatar } from "../../../../../@/components/ui/avatar";
import { Card, CardContent } from "../../../../../@/components/ui/card";
import { Separator } from "../../../../../@/components/ui/separator";
import { Skeleton } from "../../../../../@/components/ui/skeleton";
import React from "react";

export default function ChatListBoadyLoadingComponent() {
  return (
    <ScrollArea className="h-[82vh]">
      {Array(15)
        .fill(0)
        .map((_, idx) => (
          <Card key={idx} className="border-0 cursor-pointer">
            <CardContent className="flex items-center gap-2 m-3 p-0">
              <Avatar className="h-10 w-10 bg-transparent">
                <Skeleton className="h-10 w-10 rounded-full" />
              </Avatar>

              <div className="grid gap-1 flex-grow">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-3 w-40" />
              </div>

              <div className="ml-auto flex flex-col items-end">
                <Skeleton className="h-3 w-5 mb-1" />
                <Skeleton className="h-3 w-8" />
              </div>
            </CardContent>
            <Separator />
          </Card>
        ))}
    </ScrollArea>
  );
}
